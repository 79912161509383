import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BandList from './BandList';

const getBands = graphql`
  query {
    bands: directus {
      bands {
        name
        slug
        id
        body
        videos {
          id
          directus_files_id {
            id
          }
        }
        images {
          id
          directus_files_id {
            id
          }
        }
      }
    }
  }
`;

const BandComponent = () => {
  const { bands } = useStaticQuery(getBands);
  return <BandList bands={bands} />;
};

export default BandComponent;
