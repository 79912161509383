import React, { useEffect, useState } from 'react';
import * as styles from '../../css/items.module.css';
import Band from './Band';
import Title from '../Title';

const BandList = data => {
  const [sortedBands, setSortedBands] = useState([]);

  useEffect(() => {
    setSortedBands(data.bands.bands);
  }, []);
  return (
    <section className={styles.bands}>
      <Title title="Bands" subtitle="" />
      <div className={styles.center}>
        {sortedBands.map(band => (
          <Band key={band.id} band={band} />
        ))}
      </div>
    </section>
  );
};

export default BandList;
