import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from '../../css/band.module.css';

const getImage = graphql`
  query {
    directus {
      global {
        backgroundImage {
          id
        }
      }
    }
  }
`;

const Band = ({ band }) => {
  const data = useStaticQuery(getImage);
  let imageId = data.directus.global.backgroundImage.id;

  const { name, slug, images } = band;
  // const mainImage = images
  //   ? images[0].directus_files_id.imageFile.childImageSharp.fluid
  //   : img;

  if (images[0].directus_files_id.id) {
    imageId = images[0].directus_files_id.id;
  }
  const img = `https://sfb.clockworkbee.co.uk/assets/${imageId}?&width=1024&fit=contain`;

  return (
    <article className={styles.band}>
      <div
        className={styles.imgContainer}
        style={{ maxHeight: 208, overflowY: 'hidden' }}
      >
        <img
          style={{ width: '100%', height: '100%' }}
          className={styles.img}
          src={img}
          alt="gallery"
        />
        <AniLink fade className={styles.link} to={`/bands/${slug}`}>
          details
        </AniLink>
      </div>
      <div className={styles.footer}>
        <h3>{name}</h3>
        <div className={styles.info}>
          {/* <h4 className={styles.country}>
            <FaMap className={styles.icon} />
            {country || "default country"}
          </h4> */}
          {/* <div className={styles.details}>
            <h6>{days} days</h6>
            <h6>from ${price}</h6>
          </div> */}
        </div>
      </div>
    </article>
  );
};

Band.propTypes = {
  band: PropTypes.shape({
    name: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    days: PropTypes.number.isRequired,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
};

export default Band;
