import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import StyledHero from '../components/StyledHero';
import Bands from '../components/Bands/Bands';
import SEO from '../components/SEO';

const BandsPage = ({ data }) => (
  <Layout>
    <SEO title="Bands" />
    <StyledHero
      img={
        data.defaultBcg.global.backgroundImage.imageFile.childImageSharp?.fluid
      }
    />
    <Bands />
  </Layout>
);

export const query = graphql`
  query {
    defaultBcg: directus {
      global {
        backgroundImage {
          id
          imageFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default BandsPage;
